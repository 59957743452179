<template>
  <component :is="layout">
    <div class="container-md">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-3">
          <ConfigurationNav />
        </div>
        <div class="col-12 col-md-9 pb-6 pb-md-14">
          <div class="card border-0 shadow-sm">
            <div class="card-header">
              <h4>
                Edit view
              </h4>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div
                  class="form-group col-12 required"
                  :class="{ 'is-invalid': errors.has('name') }"
                >
                  <label for="name" class="control-label text-capitalize"
                    >view name</label
                  >
                  <input
                    id="name"
                    v-model="form.data.name"
                    v-validate="{ required: true }"
                    type="text"
                    name="name"
                    class="form-control"
                    placeholder="Monthly orders"
                  />
                  <span v-show="errors.has('name')" class="invalid-feedback">{{
                    errors.first("name")
                  }}</span>
                </div>
              </div>
              <div v-if="step == 1" class="form-row">
                <span
                  v-if="fieldsLoading"
                  class="spinner-border spinner-border-sm align-content-center"
                  role="status"
                  aria-hidden="true"
                />
                <div v-else class="form-group col-12 required">
                  <p class="mb-0 h4">
                    Fields
                  </p>
                  <data-table
                    :headers="headers()"
                    :data="fieldsAvailable"
                    sort-url="/order/dynamicFieldCollection"
                  >
                    <template #name="{ item }">
                      <p>{{ item.name }}</p>
                    </template>
                    <template #display="{ item }">
                      <div class="custom-control custom-switch">
                        <input
                          :id="item.key"
                          type="checkbox"
                          class="custom-control-input"
                          :checked="isChecked(item.key)"
                          @click="selectField"
                        />
                        <label class="custom-control-label" :for="item.key"
                          >&nbsp;</label
                        >
                      </div>
                    </template>
                  </data-table>
                </div>
              </div>
              <div v-else class="form-row">
                <draggable
                  v-model="selectedKeys"
                  group="people"
                  class="list-group container"
                  @update="onUpdate"
                >
                  <div
                    v-for="field in selected"
                    :key="field.key"
                    class="list-group-item"
                  >
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      class="bi bi-grip-horizontal mr-3"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                      />
                    </svg>
                    {{ field.name }}
                  </div>
                </draggable>
              </div>
            </div>
            <div class="card-footer bg-white">
              <button
                v-if="step > 1"
                type="submit"
                class="btn btn-secondary"
                @click="step = step - 1"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Back
              </button>
              <button
                type="submit"
                class="btn btn-secondary float-right"
                :disabled="loading"
                @click="nextStep"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                {{ buttonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import draggable from "vuedraggable";
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import { updateApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    draggable,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("/configurationData/" + to.params.id).then((response) => {
      next((vm) => {
        vm.form = response.data;
        if (vm.form.data.view) {
          // let fields = Object.values(vm.form.data.view);
          // fields = fields.sort((a, b) => (a.index > b.index) ? 1 : -1);
          // vm.selected = fields;
          vm.selected = vm.form.data.view;
          vm.selectedKeys = Object.keys(vm.form.data.view);
        }
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      fieldsLoading: true,
      form: {
        id: null,
        data: {
          name: "",
          view: {},
        },
      },
      fieldsAvailable: {},
      selected: {},
      selectedKeys: [],
      step: 1,
    };
  },
  computed: {
    buttonText() {
      return this.step === 1 ? "Next" : "Save view";
    },
  },
  mounted() {
    jsonAPI
      .get("/availableFields/order")
      .then((response) => {
        this.fieldsAvailable = response.data;
        this.fieldsLoading = false;
      });
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: false },
        { title: "Display column", key: "display", sortable: false },
      ];
    },
    isChecked(itemKey) {
      if (this.selected) {
        return this.selected[itemKey] ?? false;
      }
    },
    selectField(evt) {
      const id = evt.target.id;
      const val = evt.target.checked;
      if (val) {
        Object.assign(this.selected, { [id]: this.fieldsAvailable[id] });
      } else {
        delete this.selected[id];
      }
    },
    nextStep() {
      if (this.step === 1) {
        this.selectedKeys = Object.keys(this.selected);
        this.step = this.step + 1;
      } else {
        this.saveView();
      }
    },
    // Changed sorting within list
    onUpdate() {
      const newOrder = {};
      this.selectedKeys.forEach((key, index) => {
        this.selected[key].index = index;
        newOrder[key] = this.selected[key];
      });
      this.selected = newOrder;
    },
    saveView: function() {
      this.loading = true;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.form.data.view = this.selected;
          this.form = utilities.cleanData(this.form);
          await updateApi(
            `/configurationData/${this.form.id}`,
            this.form,
            "View updated"
          ).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
          });
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
